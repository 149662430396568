import React, { useEffect, useState } from "react";
import styles from "../../../../styles/MapLeftToolbox.module.scss";
import cn from "classnames/bind";
import { useWalletStore } from "../../../../store/wallet.store";
import { TabHeader } from "../TabHeader";
import { updateUserDetail } from "../../../../actions/updateUserDetail.action";
import { getUserDetail } from "../../../../actions/getUserDetail.action";

export const Profile = ({ visible }: any) => {
  const { walletAddress } = useWalletStore();
  const [bio, setBio] = useState("");
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");

  useEffect(() => {
    (async () => {
      if (walletAddress) {
        const {
          data: {
            data: {
              attributes: { bio, twitter, discord },
            },
          },
        } = await getUserDetail();
        setDiscord(discord)
        setTwitter(twitter)
        setBio(bio)
      }
    })();
  }, [walletAddress]);

  return (
    <div className={cn(styles.opacityComponent, visible && styles.active)}>
      <TabHeader label="My Profile" />

      <div className={cn(styles.text, styles.addressLabel)}>
        Address wallet:
      </div>
      <div className={cn(styles.text, styles.addressContent)}>
        {walletAddress ? walletAddress : "Log in to see Your wallet address"}
      </div>
      <div className={cn(styles.text, styles.addressLabel)}>My socials</div>
      <div className={styles.socialContainer}>
        <div className={cn(styles.text, styles.socialText)}>Discord</div>
        <input
          value={discord}
          onChange={async ({ target: { value } }) => {
            await updateUserDetail(undefined, value, undefined);
            setDiscord(value);
          }}
          className={cn(styles.socialButton)}
        />
      </div>
      <div className={styles.socialContainer}>
        <div className={cn(styles.text, styles.socialText)}>Twitter</div>
        <input
          value={twitter}
          onChange={async ({ target: { value } }) => {
            setTwitter(value);
            await updateUserDetail(undefined, undefined, value);
          }}
          className={styles.socialButton}
        />
      </div>
      <div className={styles.socialContainer}>
        <div className={cn(styles.text, styles.socialText)}>Bio</div>
        <input
          value={bio}
          onChange={async ({ target: { value } }) => {
            setBio(value);
            await updateUserDetail(value, undefined, undefined);
          }}
          className={styles.socialButton}
        />
      </div>
      <div className={styles.bodyText}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit dui tellus
        ultrices nec maecenas viverra aliquet. Aenean neque{" "}
      </div>
    </div>
  );
};

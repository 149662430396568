import create from "zustand";
import {Estate, Land} from "../types/NFT.types";
import {LANDS_MOCK} from "../__MOCKS__/nft";

interface WalletStore {
    walletAddress: string | null;
    lands: Land[];
    estates: Estate[];
    setWalletAddress: (walletAddress: string) => void;
}

export const useWalletStore = create<WalletStore>((set) => ({
    walletAddress: null,
    lands: [...LANDS_MOCK],
    estates: [],
    setWalletAddress: (walletAddress) => {
        set((state) => ({
            ...state,
            walletAddress,
        }));
    },
}));

import { AreaLL, PointFE, PointLL } from "../types/Map.types";
import { config } from "../config";
import * as Cesium from "cesium";
import { Cartesian3 } from "cesium";

export const pointLLtoFenceAreaLL = (pointLL: PointLL): AreaLL => {
  const [stepLat, stepLng] = getStepOfOneFenceInLL();
  const [aLat, aLng] = pointLL;
  const sLat = aLat;
  const sLng = aLng - stepLng;
  const stpLat = aLat + stepLat;
  return [
    [sLat, sLng],
    [stpLat, aLng],
  ];
};
export const pointLLToPointFE = (pointLL: PointLL): PointFE => {
  const [lng, lat] = pointLL;
  const { pxWidth, pxHeight, pxFenceSize } = config.map;

  const countFEW = pxWidth / pxFenceSize;
  const countFEH = pxHeight / pxFenceSize;

  const xFE = Math.floor((lng / 360) * countFEW);
  const yFE = Math.ceil((lat / 180) * countFEH);
  return [xFE, yFE];
};
export const pointFEToPointLL = (pointFE: PointFE): PointLL => {
  const [x, y] = pointFE;
  const { pxWidth, pxHeight, pxFenceSize } = config.map;

  const countFEW = pxWidth / pxFenceSize;
  const countFEH = pxHeight / pxFenceSize;

  const lng = (x * 360) / countFEW;
  const lat = (y * 180) / countFEH;
  return [lng, lat];
};

const getStepOfOneFenceInLL = (): PointLL => {
  const { pxWidth, pxHeight, pxFenceSize } = config.map;
  const countFEW = pxWidth / pxFenceSize;
  const countFEH = pxHeight / pxFenceSize;
  const stepLat = 180 / countFEH;
  const stepLng = 360 / countFEW;
  return [stepLat, stepLng];
};
export const Cartesian3ToDegrees = (
  c3: Cartesian3
): { pos: PointLL; height: number } => {
  let pos = Cesium.Cartographic.fromCartesian(c3);
  return {
    pos: [(pos.longitude / Math.PI) * 180, (pos.latitude / Math.PI) * 180],
    height: pos.height,
  };
};

import React, { useEffect, useState} from "react";
import cn from "classnames/bind";
import styles from "../../../styles/MapLeftToolbox.module.scss";
import {useMapStore} from "../../../store/map.store";
import {Cartesian3} from "cesium";
import {useCesium} from "resium";

export const SearchCoordinates = () => {
    const {setSelectedLocationByPointFE, selectedLocation} = useMapStore();

    const [x, setX] = useState<number>(0);
    const [y, setY] = useState<number>(0);
    const {scene} = useCesium();

    const searchCoordinates = () => {
        const [lat, lng] = selectedLocation.pointLL;
        scene?.camera.flyTo({destination: Cartesian3.fromDegrees(lng, lat, 10e5)});
    };
    useEffect(() => {
        setSelectedLocationByPointFE([x, y])
    }, [setSelectedLocationByPointFE, x, y])

    useEffect(() => {
        const [x, y] = selectedLocation.pointFE;
        setX(x);
        setY(y);
    }, [selectedLocation])


    const validateCoord = (value: string): boolean =>
        !!value.match("^-?\\d+$")

    const onXChange = (value: string | number) => {
        setX(Number(value));
    }
    const onYChange = (value: string | number) => {
        setY(Number(value));
    }

    return <>
        <div className={cn(styles.text, styles.coordinatesLabel)}>
            Enter the coordinates
        </div>
        <div className={styles.inputsContainer}>
            <input
                className={styles.input}
                value={x}
                onChange={({target: {value}}) => validateCoord(value) && onXChange(value)}
            />
            <input
                className={styles.input}
                value={y}
                onChange={({target: {value}}) => validateCoord(value) && onYChange(value)}
            />
        </div>
        <div className={styles.socialContainer}>
            <button
                className={cn(styles.socialButton, styles.coordinate)}
                onClick={searchCoordinates}
            >
                Search for coordinates
            </button>
        </div>
    </>
}

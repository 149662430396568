import styles from "../../../styles/MapLeftToolbox.module.scss";
import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faGlobe, faHouse, faMap } from "@fortawesome/free-solid-svg-icons";
import { walletSignIn } from "../../../helpers/wallet.helper";
import { useWalletStore } from "../../../store/wallet.store";
import { MapType, useUIStore } from "../../../store/ui.store";
import { useCesium } from "resium";
import { toast } from "react-toastify";


export const ActionButtons = () => {
  const { setWalletAddress } = useWalletStore();
  const { activeMapType, setActiveMapType } = useUIStore();
  const { camera } = useCesium();

  const icons = [
    {
      name: faHouse,
      onClick: () => camera?.flyHome(),
    },
    {
      name: activeMapType === MapType.globe ? faGlobe : faMap,
      onClick: () =>
        setActiveMapType(
          activeMapType === MapType.globe ? MapType.flat : MapType.globe
        ),
    },
  ];

  const toggleAuthenticate = async () => {
    try {
      const response = await walletSignIn();
      if (!response) return;
      setWalletAddress(response.walletAddress);
      toast.success("You are logged in");
    } catch (e) {
      toast.error("Please install Nami wallet browser extension to login");
    }
  };

  return (
    <div className={styles.rightUi}>
      {icons.map(
        (
          { name, onClick }: { name: IconDefinition; onClick: () => void },
          index
        ) => (
          <div
            key={`icon__${index}`}
            className={styles.rightUiIcon}
            onClick={onClick}
          >
            <FAIcon className={styles.faIcon} icon={name} color=" #F80183" />
          </div>
        )
      )}
      <div onClick={toggleAuthenticate} className={styles.rightUiBtn}>
        <span>Connect</span>
      </div>
    </div>
  );
};

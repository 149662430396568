import cn from "classnames/bind";
import styles from "../../../styles/MapLeftToolbox.module.scss";
import { LocalizeLabel } from "./LocalizeLabel";
import * as TabElements from "./tabs/index";
import React from "react";
import { useUIStore } from "../../../store/ui.store";

export const Tabs = () => {
  const { activeTab } = useUIStore();
  return (
    <div className={cn(styles.aside, activeTab && styles.active)}>
      <LocalizeLabel />
      <TabElements.Profile visible={"profile" === activeTab} />;
      <TabElements.General visible={"search" === activeTab} />
      <TabElements.LandList visible={"land" === activeTab} />;
    </div>
  );
};

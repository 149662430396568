import user from "../../../assets/icons/user.svg";
import styles from "../../../styles/MapLeftToolbox.module.scss";
import search from "../../../assets/icons/search.svg";
import vector from "../../../assets/icons/vector.svg";
import { faCompass, faSearch, faUser } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { Tab } from "../../../store/ui.store";

export interface TabButton {
  tabName: Tab | null;
  src: string;
  className: string;
  icon?: IconDefinition;
}

export const tabButtons: TabButton[] = [
  {
    tabName: "profile",
    src: user,
    className: styles.userIcon,
    icon: faUser,
  },
  {
    tabName: "search",
    src: search,
    className: styles.searchIcon,
    icon: faSearch,
  },
  {
    tabName: "land",
    src: vector,
    className: styles.vectorIcon,
    icon: faCompass,
  },
];

import cn from "classnames/bind";
import styles from "../../../styles/MapLeftToolbox.module.scss";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useUIStore } from "../../../store/ui.store";

export const ChevronToggler = () => {
  const { activeTab, setActiveTab, lastActiveTab, setLastActiveTab } =
    useUIStore();

  const onChevron = () =>
    activeTab
      ? () => {
          setLastActiveTab(activeTab);
          setActiveTab(null);
        }
      : () => {
          setActiveTab(lastActiveTab);
        };

  return (
    <div
      onClick={
        activeTab
          ? () => {
              setLastActiveTab(activeTab);
              setActiveTab(null);
            }
          : () => {
              setActiveTab(lastActiveTab);
            }
      }
      className={cn(styles.iconChevron, activeTab && styles.active)}
    >
      <FAIcon color="white" onClick={onChevron} icon={faChevronRight} />
    </div>
  );
};

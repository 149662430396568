import React from 'react'
import '../styles/Map.scss'
import MapLandLayer from "./MapLandLayer";
import MapOwnedLandsLayer from "./MapOwnedLandsLayer";

const MapLayers = () => {
    return (
        <>
            <MapOwnedLandsLayer/>
            <MapLandLayer/>
        </>
    )

}

export default MapLayers

import {useEffect, useState} from 'react'
import {useCesium} from "resium";
import {SkyBox} from "cesium";

const MapLayers = () => {

    const [sources] = useState({
        positiveX: require('../assets/skybox/px.png').default,
        negativeX: require('../assets/skybox/nx.png').default,
        positiveY: require('../assets/skybox/ny.png').default, // y is up in cesium (change ny with py)
        negativeY: require('../assets/skybox/py.png').default,
        positiveZ: require('../assets/skybox/pz.png').default,
        negativeZ: require('../assets/skybox/nz.png').default
    });

    const {scene} = useCesium();

    useEffect(() => {
        if (!scene?.skyBox) return;
        scene!.skyBox = new SkyBox({
            sources: sources,
        });
    }, [scene, sources]);

    return null;
}

export default MapLayers

import apiAxios from "../services/axios.service";

export const updateUserDetail = async (
  bio?: string,
  discord?: string,
  twitter?: string
) => {
  const bodyFormData = new FormData();
  bodyFormData.append("data", JSON.stringify({ bio, discord, twitter }));

  return await apiAxios.put("/users/updateMe", bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

import {Config} from "../types/Config.types";

export const configuration: Config = {
    map: {
        pxHeight: 17216,
        pxWidth: 34432,
        pxFenceSize: 32,
        tileUrl: "https://d3ryr8hjz8j48e.cloudfront.net/",
        infoUrl: "http://localhost:3001",
        imageMinZoom: 1,
        imageMaxZoom: 8,
        minZoomCameraHeight: 1000000,
        maxZoomCameraHeight: 30000000,
    },
    backend: {
        url: 'http://localhost:80/api'
    },
    defaults: {
        initialPosition: {
            lat: 18,
            lng: 48,
            height: 8900000,
            animationDuration: 3,
        }
    }
}

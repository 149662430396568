import { Land } from "../types/NFT.types";

export const LANDS_MOCK: Land[] = [
  { x: 12, y: 32, type: "water" },
  { x: 2, y: 43, type: "dirt" },
  { x: 41, y: 2, type: "grass" },
  { x: 42, y: 2, type: "grass" },
  { x: 45, y: 5, type: "grass" },
  { x: 0, y: 0, type: "grass" },
  { x: -20, y: -50, type: "grass" },
];

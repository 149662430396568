import React from "react";
import styles from "../../../../styles/MapLeftToolbox.module.scss";
import cn from "classnames/bind";

import Three from "../Three";
import { SearchCoordinates } from "../SearchCoordinates";
import { TabHeader } from "../TabHeader";
import { DesktopUser } from "../DesktopUser";

export const General = ({ visible }: any) => {
  return (
    <div className={cn(styles.opacityComponent, visible && styles.active)}>
      <TabHeader label="General" />
      <SearchCoordinates />
      <div className={styles.btnResetZoom}>Reset zoom</div>
      <Three />
      <DesktopUser />
    </div>
  );
};

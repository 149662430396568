import { register } from "../actions/action.register";

declare global {
  interface Window {
    cardano: {
      nami: {
        enable: any;
      };
      signData: any;
    };
  }
}




export const walletSignIn = async () => {
    const nami = await window.cardano.nami.enable();
    const signRequest = {
      type: "SIGN_IN_REQUEST",
      timestamp: Date.now(),
    };
    const address = (await nami.getUsedAddresses())[0];
    const signRequestInHex = new Buffer(JSON.stringify(signRequest)).toString(
      "hex"
    );

    const token: string = await window.cardano.signData(
      address,
      signRequestInHex
    );

    const { walletAddress } = await register(token);
    return { walletAddress };
};

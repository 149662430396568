import React, {useState} from 'react'
import {ImageryLayer} from "resium";
import {TileMapServiceImageryProvider, WebMercatorTilingScheme} from "cesium";
import {config} from "../config";

const MapLayers = () => {
    const {imageMinZoom, imageMaxZoom, tileUrl} = config.map;

    const [LandImaginaryLayer] = useState(new TileMapServiceImageryProvider({
        url: tileUrl,
        minimumLevel: imageMinZoom,
        maximumLevel: imageMaxZoom,
        tilingScheme: new WebMercatorTilingScheme()
    }));

    return <ImageryLayer imageryProvider={LandImaginaryLayer}/>
}

export default MapLayers

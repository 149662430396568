import logo from "../../../../assets/images/logo.png";
import React from "react";
import Hamburger from "hamburger-react";
import styles from '../../../../styles/MapLeftToolbox.module.scss'

export const MobileNavbar = ({ isActive, setIsActive }: any) => {
  return (
    <nav className={styles.navbarMobile}>
      <img className="navbar--logo" src={logo} alt="logo" />
      <Hamburger
        color="white"
        toggled={isActive}
        toggle={() => setIsActive(!isActive)}
        size={25}
      />
    </nav>
  );
};

import apiAxios from "../services/axios.service";

export const register = async (token: string) => {
  const {
    data: {
      jwt,
      user: { walletAddress },
    },
  } = await apiAxios.post(
    "/auth/local/authenticate",
    JSON.stringify({
      token,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  apiAxios.interceptors.request.use((config) => {
    if (!config) {
      config = {};
    }
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.Authorization = `Bearer ${jwt}`;
    return config;
  });

  return { walletAddress };
};

import React, { Fragment } from "react";
import { useWalletStore } from "../store/wallet.store";
import HighLightedTile from "./HighLightedTile";
import { Color } from "../consts/Color.const";

const MapOwnedLandsLayer = () => {
  const { lands } = useWalletStore();

  return (
    <>
      {lands.map((land, index) => (
        <Fragment key={`land__${index}`}>
          <HighLightedTile x={land.x} y={land.y} color={Color.blue} />
        </Fragment>
      ))}
    </>
  );
};

export default MapOwnedLandsLayer;

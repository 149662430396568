import React from "react";
import styles from "../../../styles/MapLeftToolbox.module.scss";
import avatar from "../../../assets/images/avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import cn from "classnames/bind";

export const DesktopUser = () => {
  return (
    <>
      <div className={cn(styles.text, styles.ownedByLabel)}>Owned by</div>
      <div className={styles.desktopUser}>
        <img height="52px" width="52px" src={avatar} alt="avatar" />
        <div className={styles.mobileUserText}>
          sdjfvbZebra2983029
          <br />
          <span>Land (468,345)</span>
          <div>
            <FontAwesomeIcon
              className={styles.mobileViewIcon}
              icon={faMessage}
              color="#D7D1DB"
              fontSize={22}
            />
            <FontAwesomeIcon
              className={styles.mobileViewIcon}
              icon={faDiscord}
              color="#D7D1DB"
              fontSize={22}
            />
            <FontAwesomeIcon
              className={styles.mobileViewIcon}
              icon={faTwitter}
              color="#D7D1DB"
              fontSize={22}
            />
          </div>
        </div>
      </div>
    </>
  );
};

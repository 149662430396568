import apiAxios from "../services/axios.service";
import { PointFE } from "../types/Map.types";

export const getLandInfo = async (pointFE: PointFE) => {
  return (
    await apiAxios.get("/land", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      params: {
        x: pointFE[0],
        y: pointFE[1],
      },
    })
  ).data.data;
};

import { ToastContainer } from "react-toastify";
import Map from "./components/Map";
import "react-toastify/dist/ReactToastify.css";
import { useUIStore } from "./store/ui.store";
import { toastContainerProps } from "./helpers/toast.helper";

const App = () => {
  const { activeTab } = useUIStore();
  const isSidebarOpen = !!activeTab

  return (
    <>
      <ToastContainer
        {...toastContainerProps(isSidebarOpen)}
      />
      <Map />
    </>
  );
};

export default App;

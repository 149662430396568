import create from "zustand";

export type Tab = "profile" | "search" | "land";

export type ActiveLandInfo = {
  x: number;
  y: number;
  owner: string;
  landType:LandType;
};

export enum LandType{
  WATER="WATER",
  PORTAL="PORTAL",
  SAND="SAND",
  GRASS="GRASS",
  MOUNTAIN_LOW="MOUNTAIN_LOW"
}

export enum MapType {
  globe="globe",
  flat="flat",
}

export interface UiStore {
  activeLand?: ActiveLandInfo;
  activeTab: Tab | null;
  setActiveTab: (tab: Tab | null) => void;
  lastActiveTab: Tab;
  setLastActiveTab: (tab: Tab) => void;
  activeMapType: MapType;
  setActiveMapType: (activeMapType: MapType) => void;
  setActiveLand: (activeLand: ActiveLandInfo) => void;
}

export const useUIStore = create<UiStore>((set) => ({
  activeLand: undefined,
  setActiveLand: (activeLand) => {
    set((state) => ({
      ...state,
      activeLand,
    }));
  },
  activeMapType: MapType.globe,
  setActiveMapType: (activeMapType) => {
    set((state) => ({
      ...state,
      activeMapType,
    }));
  },
  activeTab: null,
  lastActiveTab: "profile",
  setActiveTab: (tab) => {
    set((state) => ({
      ...state,
      activeTab: tab,
    }));
  },
  setLastActiveTab: (tab) => {
    set((state) => ({
      ...state,
      lastActiveTab: tab,
    }));
  },
}));

import React, { useCallback, useEffect, useRef } from "react";
import "../styles/Map.scss";
import { CesiumComponentRef, CesiumMovementEvent, Globe, Viewer } from "resium";
import { Cartesian2, Color, Math as CMath, Viewer as CViewer } from "cesium";
import MapSkyBox from "./MapSkyBox";
import MapSelector from "./MapSelector";
import { useMapStore } from "../store/map.store";
import MapLayers from "./MapLayers";
import { MapToolbox } from "./MapToolbox/MapToolbox";
import { useWidth } from "../hooks/useWitdh";
import cn from "classnames/bind";
import { MapType, useUIStore } from "../store/ui.store";
import { getLandInfo } from "../actions/getLandInfo.action";
import { config } from "../config";
import { MobileComponents } from "./MapToolbox/components/mobile/MobileComponents";

const CesiumWrapper = () => {
  const { activeMapType } = useUIStore();
  const { setSelectedLocationByPointLL, selectedLocation } = useMapStore();
  const { pointFE } = selectedLocation;

  const cesium = useRef<CesiumComponentRef<CViewer>>(null);
  const { map } = config;
  const { isMobile } = useWidth();
  const { activeTab, setActiveLand } = useUIStore();

  const loadLandInfo = useCallback(async () => {
      const land = await getLandInfo(pointFE);
      setActiveLand(land);
  },[pointFE, setActiveLand])

  useEffect(() => {
    loadLandInfo().catch(console.error);
  }, [loadLandInfo]);

  useEffect(() => {
    setDefaults();
    // eslint-disable-next-line
  }, [cesium]);

  useEffect(() => {
    activeMapType === MapType.globe && cesium?.current?.cesiumElement?.scene.morphTo3D();
    activeMapType === MapType.flat && cesium?.current?.cesiumElement?.scene.morphTo2D();
  }, [activeMapType]);

  const setDefaults = () => {
    cesium.current!.cesiumElement!.scene.screenSpaceCameraController.minimumZoomDistance =
      map.minZoomCameraHeight;
    cesium.current!.cesiumElement!.scene.screenSpaceCameraController.maximumZoomDistance =
      map.maxZoomCameraHeight;
  };

  const getLocationFromScreenXY = (
    x: number,
    y: number
  ): { lat: number; lng: number } | null => {
    const scene = cesium.current!.cesiumElement?.scene!;
    const ellipsoid = scene.globe.ellipsoid;
    const cartesian = scene.camera.pickEllipsoid(
      new Cartesian2(x, y),
      ellipsoid
    )!;

    if (!cartesian) {
      throw new Error("outside map click");
    }

    const { latitude, longitude } =
      ellipsoid.cartesianToCartographic(cartesian);
    const lng = CMath.toDegrees(longitude);
    const lat = CMath.toDegrees(latitude);
    return { lat, lng };
  };
  const isMapClick = (m: CesiumMovementEvent) => {
    const scene = cesium.current!.cesiumElement?.scene!;
    const ellipsoid = scene.globe.ellipsoid;
    return !!scene.camera.pickEllipsoid(
      new Cartesian2(m.position!.x, m.position!.y),
      ellipsoid
    )!;
  };

  const handleMapClick = (m: CesiumMovementEvent) => {
    const coordsLL = getLocationFromScreenXY(m.position!.x, m.position!.y)!;
    const { lat, lng } = coordsLL;
    setSelectedLocationByPointLL([lat, lng]);
  };

  const handleClick = (m: CesiumMovementEvent) => {
    isMapClick(m) && handleMapClick(m);
  };

  const isTabOpen = !!activeTab;

  return (
    <Viewer
      className={cn(isTabOpen && "active")}
      ref={cesium}
      onClick={handleClick}
      selectionIndicator={false}
      infoBox={false}
      imageryProvider={false}
      baseLayerPicker={false}
      geocoder={false}
      animation={false}
      timeline={false}
      full={false}
    >
      <Globe baseColor={new Color(0, 0, 0)} showGroundAtmosphere={false} />
      <MapLayers />
      <MapSkyBox />
      <MapSelector />
      {isMobile && <MobileComponents />}
      <MapToolbox />
    </Viewer>
  );
};

export default CesiumWrapper;

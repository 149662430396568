import React from "react";
import styles from "../../../../styles/MapLeftToolbox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faGlobe, faHouse, faMap, faSearch } from "@fortawesome/free-solid-svg-icons";
import cn from "classnames/bind";
import { MapType, useUIStore } from "../../../../store/ui.store";
import { useCesium } from "resium";

export const MobileToolbar = ({ active, setActive }: any) => {
  const { activeMapType, setActiveMapType } = useUIStore();
  const { camera } = useCesium();
  const tabs = [
    {
      label: "General",
      icon: faSearch,
      onClick: () =>
        "General" === active ? setActive(null) : setActive("General"),
    },
    {
      label: "Home",
      icon: faHouse,
      onClick: () => {
        setActive(null);
        camera?.flyHome();
      },
    },
    {
      label: `${activeMapType === MapType.globe ? "3D" : "2D"}`,
      icon: activeMapType === MapType.globe ? faGlobe : faMap,
      onClick: () => {
        setActive(null);
        setActiveMapType(activeMapType === MapType.globe ? MapType.flat : MapType.globe);
      },
    },
    {
      label: "Help",
      icon: faCircleQuestion,
      onClick: () => {},
    },
  ];

  return (
    <div className={styles.bottom}>
      {tabs.map(({ label, icon, onClick }, index) => (
        <div
          key={`mobileTab__${index}`}
          onClick={onClick}
          className={cn(styles.bottomBlock, label === active && styles.active)}
        >
          <FontAwesomeIcon
            className={styles.faIcon}
            icon={icon}
            color="#F80183"
            fontSize={24}
          />
          <label className={styles.bottomLabel}>{label}</label>
        </div>
      ))}
    </div>
  );
};

import React from "react";
import styles from "../../styles/MapLeftToolbox.module.scss";
import plurius from "../../assets/icons/plurius.svg";
import cn from "classnames/bind";
import {useUIStore} from "../../store/ui.store";
import {ActionButtons} from "./components/ActionButtons";
import {Tabs} from "./components/Tabs";
import {ChevronToggler} from "./components/ChevronToggler";
import {TabButtons} from "./components/TabButtons";
import {ZoomControl} from "./components/ZoomControl";

export const MapToolbox = () => {
    const {activeTab} = useUIStore();

    return (
        <>
            <ZoomControl/>
            <ActionButtons/>
            <Tabs/>
            <div className={styles.container}>
                <ChevronToggler/>
                <img
                    alt="Plurius Icon"
                    src={plurius}
                    className={cn(styles.logoIcon, activeTab && styles.disable)}
                />
                <TabButtons/>
            </div>
        </>
    );
};

MapToolbox.defaultProps = {};

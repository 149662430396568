import React, { useState } from "react";
import { MobileNavbar, MobileBackgroundView, MobileToolbar } from "../mobile";
import styles from "./../../../../styles/MapLeftToolbox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useMapStore } from "../../../../store/map.store";

export const MobileComponents = () => {
  const [active, setActive] = useState<"General" | "Help" | undefined>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const {
    selectedLocation: { pointFE },
  } = useMapStore();
  
  return (
    <>
      <MobileBackgroundView active={active} />
      <MobileNavbar isActive={isActive} setIsActive={setIsActive} />
      <MobileToolbar active={active} setActive={setActive} />
      <div className={styles.localize}>
        <FontAwesomeIcon icon={faLocationDot} color=" #F80183" />
        <label className={styles.localizeLabel}> {pointFE[0]},{pointFE[1]}</label>
      </div>
    </>
  );
};

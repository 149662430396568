import React from "react";
import cn from "classnames/bind";
import styles from "../../../../styles/MapLeftToolbox.module.scss";
import avatar from "../../../../assets/images/avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faUser } from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";
import dom from "../../../../assets/images/dom.png";

export const MobileBackgroundView = ({ active }: { active?: string }) => {
  return (
    <div
      className={cn(styles.mobileView, active === "General" && styles.active)}
    >
      <div className={styles.mobileUser}>
        <img height="52px" width="52px" src={avatar} alt="avatar" />
        <div className={styles.mobileUserText}>
          sdjfvbZebra2983029
          <br />
          <span>Land (468,345)</span>
          <div>
            <FontAwesomeIcon
              className={styles.mobileViewIcon}
              icon={faMessage}
              color="#D7D1DB"
              fontSize={22}
            />
            <FontAwesomeIcon
              className={styles.mobileViewIcon}
              icon={faDiscord}
              color="#D7D1DB"
              fontSize={22}
            />
            <FontAwesomeIcon
              className={styles.mobileViewIcon}
              icon={faTwitter}
              color="#D7D1DB"
              fontSize={22}
            />
          </div>
        </div>
      </div>
      <div className={styles.mobileLand}>
        <div className={styles.mobileLandText}>Land (456,345)</div>
        <img src={dom} width="152px" height="152px" alt="house" />
      </div>
      <div className={styles.mobileUserParagraph}>
        <FontAwesomeIcon
          className={styles.mobileViewIcon}
          icon={faUser}
          color="#D7D1DB"
          fontSize={16}
        />
        <p className={styles.mobileUserParagraphText}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit dui
          tellus ultrices nec maecenas viverra aliquet.
        </p>
      </div>
    </div>
  );
};

import React from "react";
import { TabButton, tabButtons } from "./tabsButtons";
import cn from "classnames/bind";
import styles from "../../../styles/MapLeftToolbox.module.scss";
import { useUIStore } from "../../../store/ui.store";

export const TabButtons = () => {
  const { activeTab, setActiveTab } = useUIStore();

  return (
    <>
      {tabButtons.map(({ tabName, src }: TabButton, index) => {
        return (
          <div
            key={index}
            className={cn(
              styles.iconWrapper,
              tabName === activeTab && styles.active
            )}
            onClick={() =>
              tabName === activeTab ? setActiveTab(null) : setActiveTab(tabName)
            }
          >
            <img alt="" src={src} />
          </div>
        );
      })}
    </>
  );
};

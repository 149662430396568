import create from "zustand";
import { AreaLL, PointFE, PointLL } from "../types/Map.types";
import {
  pointFEToPointLL,
  pointLLtoFenceAreaLL,
  pointLLToPointFE,
} from "../helpers/dimensionsConverter.helper";

interface MapStore {
  selectedLocation: {
    pointLL: PointLL;
    pointFE: PointFE;
    areaLL: AreaLL;
  };
  setSelectedLocationByPointLL: (pointLL: PointLL) => void;
  setSelectedLocationByPointFE: (pointLL: PointFE) => void;
}

export const useMapStore = create<MapStore>((set) => ({
  selectedLocation: {
    pointFE: [0, 0],
    pointLL: [0, 0],
    areaLL: [
      [0, 0],
      [0, 0],
    ],
  },
  setSelectedLocationByPointLL: (pointLL) => {
    const selectedFE = pointLLToPointFE(pointLL);
    const areaLL = pointLLtoFenceAreaLL(pointLL);
    set((state) => ({
      ...state,
      selectedLocation: {
        ...state.selectedLocation,
        pointFE: selectedFE,
        pointLL,
        areaLL: areaLL,
      },
    }));
  },
  setSelectedLocationByPointFE: (pointFE) => {
    const pointLL = pointFEToPointLL(pointFE);
    const areaLL = pointLLtoFenceAreaLL(pointLL);
    set((state) => ({
      ...state,
      selectedLocation: {
        ...state.selectedLocation,
        pointFE: pointFE,
        pointLL,
        areaLL: areaLL,
      },
    }));
  },
}));

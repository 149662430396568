import React, {useMemo} from 'react';
import {Entity, RectangleGraphics} from "resium";
import {Color, Rectangle} from "cesium";
import {pointFEToPointLL, pointLLtoFenceAreaLL} from "../helpers/dimensionsConverter.helper";

interface Props {
    x: number
    y: number
    color?: Color
}

const HighlightedTile = ({x, y, color}: Props) => {

    const selectedRectangle = useMemo(() => {
        const pointLL = pointFEToPointLL([x, y]);
        const areaLL = pointLLtoFenceAreaLL(pointLL);
        const [[s, w], [n, e]] = areaLL;
        return Rectangle.fromDegrees(w, s, e, n);
    }, [x, y])

    return (
        <Entity selected={false} point={{pixelSize: 100}}>
            <RectangleGraphics fill={true} material={color} coordinates={selectedRectangle}/>
        </Entity>
    )
}

export default HighlightedTile

import styles from "../../../styles/MapLeftToolbox.module.scss";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useMapStore } from "../../../store/map.store";

export const LocalizeLabel = () => {
  const { selectedLocation } = useMapStore();
  const { pointFE } = selectedLocation;
  
  return (
    <div className={styles.localize}>
      <FAIcon icon={faLocationDot} color=" #F80183" />
      <label className={styles.localizeLabel}>
        {pointFE[0]},{pointFE[1]}
      </label>
    </div>
  );
};

import React from 'react';
import {useMapStore} from "../store/map.store";
import HighLightedTile from "./HighLightedTile";
import {Color} from "../consts/Color.const";

const MapSelector = () => {
    const {selectedLocation} = useMapStore();
    const {pointFE} = selectedLocation

    return (
        <HighLightedTile x={pointFE[0]} y={pointFE[1]} color={Color.red}/>
    )
}


export default MapSelector

import pluriusFull from "../../../assets/images/pluriusFull.svg";
import styles from "../../../styles/MapLeftToolbox.module.scss";
import cn from "classnames/bind";
import React from "react";

export const TabHeader = ({ label }: { label: string }) => {
  return (
    <>
      <img
        alt="Plurius logo"
        src={pluriusFull}
        className={styles.pluriusFull}
      />

      <div className={cn(styles.iconWrapper, styles.tabHeader)}>
        <div className={cn(styles.headerH06, styles.headerButton)}>{label}</div>
      </div>
    </>
  );
};

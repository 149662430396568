import React from "react";
import styles from "../../../../styles/MapLeftToolbox.module.scss";
import cn from "classnames/bind";
import { TabHeader } from "../TabHeader";
import { useWalletStore } from "../../../../store/wallet.store";
import { Land } from "../../../../types/NFT.types";
import landImage from "../../../../assets/lands/1.png";
import { useCesium } from "resium";
import { PointFE } from "../../../../types/Map.types";
import { Cartesian3 } from "cesium";
import { pointFEToPointLL } from "../../../../helpers/dimensionsConverter.helper";
import { Tooltip } from "@mui/material";

export const LandList = ({ visible }: any) => {
  const { lands } = useWalletStore();
  const { scene } = useCesium();

  const onLandClick = (pointFE: PointFE) => {
    const [lat, lng] = pointFEToPointLL(pointFE);
    scene?.camera.flyTo({
      destination: Cartesian3.fromDegrees(lng, lat, 10e5),
    });
  };

  return (
    <div className={cn(styles.opacityComponent, visible && styles.active)}>
      <TabHeader label="Land" />

      <div className={cn(styles.text, styles.landButtons)}>
        <button className={cn(styles.landBtn, styles.active)}>Land</button>
        <Tooltip title="Comming soon...">
          <button className={styles.landBtn}>Estate</button>
        </Tooltip>
      </div>

      {lands.map(({ x, y, type }: Land, index) => (
        <div
          key={`land__${index}`}
          className={styles.yLand}
          onClick={() => onLandClick([x, y])}
        >
          <img className={styles.yLandImg} src={landImage} alt="land" />
          <label className={styles.yLandLabel}>
            {type} - ({x},{y})
          </label>
        </div>
      ))}
    </div>
  );
};

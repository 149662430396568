import axios from "axios";
import {config} from "../config";
import { toast } from "react-toastify";


const apiAxios = axios.create({
    baseURL: config.backend.url,
    timeout:5000
});

apiAxios.interceptors.response.use((response) => response, (error) => {
    //#TODO handle Access-Control-Allow-Headers
    if(error.message !== "Network Error"){
        toast.error(error.message)
    }
});

export default apiAxios;

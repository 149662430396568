import styles from "../../../styles/MapLeftToolbox.module.scss";
import {FontAwesomeIcon as FAIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useCesium} from "resium";
import {Cartesian3} from "cesium";
import {Cartesian3ToDegrees} from "../../../helpers/dimensionsConverter.helper";
import {config} from "../../../config";

export const ZoomControl = () => {

    const {camera} = useCesium();
    const {map} = config;

    const onZoomClick = (zoomMultiplier: number = 1.5) => {
        const {pos, height} = Cartesian3ToDegrees(camera!.position);
        let newHeight = height * zoomMultiplier;
        if (newHeight > map.maxZoomCameraHeight) {
            newHeight = map.maxZoomCameraHeight;
        }
        if (newHeight < map.minZoomCameraHeight) {
            newHeight = map.minZoomCameraHeight;
        }
        const newPosition = Cartesian3.fromDegrees(pos[0], pos[1], newHeight);
        camera?.flyTo({destination: newPosition, duration: 0.3})
    }

    return (
        <div className={styles.plusMinus}>
            <button className={styles.zoomCircle} onClick={() => onZoomClick(0.5)}>
                <FAIcon color="#F80183" icon={faPlus} fontSize={30}/>
            </button>
            <button className={styles.zoomCircle} onClick={() => onZoomClick(1.5)}>
                <FAIcon color="#F80183" icon={faMinus} fontSize={30}/>
            </button>
        </div>
    );
};

import { TypeOptions } from "react-toastify";
import { ToastPosition } from "react-toastify/dist/types";
import styles from "./../styles/Toast.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import React from "react";

type ToastProps = {
  type?: TypeOptions;
  defaultClassName?: string;
  position?: ToastPosition;
  rtl?: boolean;
};

const switchToastClass = (toastType?: TypeOptions) => {
  switch (toastType) {
    case "success":
      return styles.success;
    case "error":
      return styles.error;
    case "info":
      return styles.info;
    case "warning":
      return styles.warning;
  }
};

export const toastClassNameSelect = (toast?: (ToastProps | undefined)) =>
  `${switchToastClass(toast?.type)} Toastify__toast`;

export const toastContainerProps = (isSidebarOpen: boolean) => ({
  hideProgressBar: true,
  style: {
    transition: "1s",
    left: isSidebarOpen ? 444 : 100,
  },
  closeButton: ({
    closeToast,
  }: {
    closeToast: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  }) => (
    <button className={styles.close} onClick={closeToast}>
      <FontAwesomeIcon style={{ color: "black" }} icon={faClose} />
    </button>
  ),
  autoClose: 5000,
  className: styles.toast,
  toastClassName: toastClassNameSelect,
});

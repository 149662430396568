import React, { Suspense, useEffect, useRef } from "react";
import "../../../styles/Square.scss";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls, PerspectiveCamera, useAnimations, useGLTF } from "@react-three/drei";
import styles from "./../../../styles/MapLeftToolbox.module.scss";
import iconthree from "../../../assets/icons/3dIcon.svg";
import cn from "classnames/bind";
import { useMapStore } from "../../../store/map.store";
import { ActiveLandInfo, LandType, useUIStore } from "../../../store/ui.store";


const switchLand = (land?:ActiveLandInfo) =>{
  switch(land?.landType){
    case LandType.WATER:
      return "lands/boat.gltf"
    case LandType.PORTAL:
      return "lands/portal.gltf"
    case LandType.SAND:
      return "lands/beach.gltf"
    case LandType.GRASS:
      return "lands/houseWithGrass.gltf"
    case LandType.MOUNTAIN_LOW:
      return "lands/bear.gltf"
    default:
      return "./house.gltf"
  }
}


const Three = () => {
  const {
    selectedLocation: { pointFE },
  } = useMapStore();
  
  const Scene = () => {
    const {activeLand} = useUIStore()
    const group = useRef();
    const { scene, animations, } = useGLTF(switchLand(activeLand));
    const { actions } = useAnimations(animations, group);
    
    useEffect(() => {
      for (const value of Object.keys(actions)) {
        actions[value].play();
      }
    });
    
    return (
      <group ref={group} dispose={null}>
        <primitive object={scene} scale={0.4} />
      </group>
    );
  };

  return (
    <>
      <div className={cn(styles.text, styles.coordinatesLabel)}>Preview</div>
      <div className={styles.land}>
        <div className={styles.landLabel}>
          {pointFE[0]},{pointFE[1]}
        </div>
        <img src={iconthree} className={styles.iconThree} alt="3D Icon" />
        <div className={styles.nftItem}>
          <Canvas frameloop="demand" gl={{ antialias: true }}>
            <ambientLight intensity={0.2} />
            <Suspense fallback={null}>
              <Scene />
              <PerspectiveCamera
                makeDefault
                position={[5, 3.6000000000000003, 6]}
                fov={30}
                zoom={0.32}
              />
              <OrbitControls target={[0, 1.5, 0]} enableZoom={false} />
              <Environment preset="sunset" files="plan.hdr" path={"/"} />
            </Suspense>
          </Canvas>
        </div>
      </div>
    </>
  );
};

export default Three;
